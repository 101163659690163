
/* lg */
@media (min-width: 1200px) {

}

@media (max-width: 1199px) {
.header .navbar-nav li a {
    font-size:11px;
}

}

/* md */
@media (min-width: 992px) and (max-width: 1199px) {

}

/* sm */
@media (min-width: 768px) and (max-width: 991px) {

    .top-search{
        width: 170px;
    }

    .post-thumb img{
        width: 100%;
    }
    .social-icons-search {
        float: right;
    }

}

/* xs */
@media (max-width: 767px) {

    .carousel {
        margin-top: 20px;
    }

    .ovalBox label {
        float: none;
        text-align: left;
        width:auto;
    }

    .ovalBox select {
        width:100% !important;
    }


    footer .footer-links {
        display: block;
        padding-left: 0;
    }

    .facebook-pages {
        display: none;
    }

    .header .navbar-nav li.nav-shop {
        margin-left:0px;
    }

    .navbar-fixed-bottom .navbar-collapse, .navbar-fixed-top .navbar-collapse{
        max-height: 100%;
    }

    .header .navbar-nav li{
        padding: 14px;
    }

    .header .dropdown .dropdown-menu{
        margin-top: 10px;
    }

    .header .dropdown:hover .dropdown-menu, .header .dropdown .dropdown-submenu:hover>.dropdown-menu{
        display: none;
        opacity: 1;
    }
    .header .open .dropdown-menu, .header .dropdown .dropdown-submenu.opem>.dropdown-menu{
        display: block !important;
        opacity: 1 !important;
    }
    ul.nav li.dropdown:hover > ul.dropdown-menu li ul.dropdown-menu {
        position: absolute;
        left: 10px;
        top: 100%;
    }

    .fixed-nav .main-logo {
        margin-top: 60px;
    }

    .col-md-4 {
        padding-left: 0;
    }


    .left-main-col .row {
        padding: 25px 5px;
    }

    .left-main-col {
       margin-bottom: 20px;
    }

    .main-content {
       margin-top: 45px;
    }


    aside.widget {
        margin-bottom: 20px;
    }
}

/* XS Portrait */
@media (max-width: 479px) {

    a.ss-read-more {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .main-slide {
        max-height:300px;
    }

    .slider-text {
        padding: 20px 20px 20px;
    }

    .slider-text h2 {
        line-height: 30px;
        font-weight: 500;
        font-size: 28px;
    }

    .main-content {
        margin-top: 20px;
    }

    .main-wrap {
        padding-top: 0px;
    }

    .navbar-fixed-top{
        position: relative;
    }
    .admin-bar .navbar-fixed-top{
        margin-top: auto;
    }

    .navbar-fixed-bottom .navbar-collapse, .navbar-fixed-top .navbar-collapse{
        max-height: 100%;
    }

}