/*----------------------------------------
	google fonts
    ------------------------------------------*/
    @import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700|Lora:400,700);

/*----------------------------------------
    elements
    ------------------------------------------*/
    body {
        font-family: 'Lora', serif;
        color: #222222;
        font-size: 15px;
        font-weight: 400;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-font-smoothing: antialiased;
        background: #efefef;
        line-height:26px;
    }

    a:active,
    a:focus {
        outline: none !important;
    }

    a {
        text-decoration: none !important;
        color: #22569E;
    }

    a:hover, a:focus {
        color: #22569E;
    }

    a, a:hover, .btn, .btn:hover, input.form-control:focus, textarea.form-control:focus, input[type="submit"], a.ss-read-more, a.ss-read-more:hover {
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

    p {
        line-height: 26px;
        color: #222222;
    }

    a img,
    iframe {
        border: none !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #222222;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;
        margin-bottom: 30px;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    input:focus {
        outline: none;
    }

    input[type="password"] {
        border: 1px solid #e2e2e2;
        padding: 6px 12px;
        font-size: 12px;
        color: #aaaaaa;
        letter-spacing: 1px;
    }

    input[type="submit"] {
        color: #fff;
        background-color: #22569E;
        padding: 6px 12px;
        border: 1px solid #22569E;
    }

    pre {
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
    }

    .media-left, .media .pull-left {
        padding-right: 20px;
    }

    .admin-bar .navbar-fixed-top {
        margin-top: 32px;
    }

/*----------------------------------------
    Header
    ------------------------------------------*/
    .main-logo-section {
        border-bottom: 1px solid #e2e2e2;
    }

    .main-logo h1{
        margin-bottom: 12px;
    }

    .home .main-logo-section {
        border-bottom: 0;
    }

    .main-logo {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .text-logo a {
        font-size: 38px;
    }

    .fixed-nav .main-logo {
        margin-top: 110px;
    }

    .header {
        background-color: #fff;
    }

    .header .navbar-nav li {
        padding: 19px 14px;
    }

    .header .navbar-nav li.nav-shop {
        margin-left:30px;
    }

    .header .navbar-nav li a {
        color: #000;
        font-size: 13px;
        font-weight: 400;
        text-transform: uppercase;
        padding: 0;
        letter-spacing: 1px;
    }

    .header .navbar-nav li a:hover,
    .header .navbar-nav li a:focus {
        color: #000;
        background-color: transparent;
    }

    .header .dropdown-menu {
        min-width: 200px;
    }

    .header .dropdown .dropdown-menu li {
        padding: 0;
    }

    .header .dropdown:hover .dropdown-menu,
    .header .dropdown .dropdown-submenu:hover > .dropdown-menu {
        display: block;
        opacity: 1;
    }

    .header .dropdown .dropdown-menu,
    .header .dropdown .dropdown-submenu .dropdown-menu {
        opacity: 0;
    }

    .header .dropdown-submenu {
        position: relative;
    }

    .header .dropdown-submenu > a:after {
        font-family: 'FontAwesome';
        content: "\f105";
        float: right;
    }

    ul.nav li.dropdown:hover > ul.dropdown-menu li ul.dropdown-menu {
        position: absolute;
        left: 100%;
        top: -2px;
    }

    .icon-bar {
        background-color: #fff;
    }

    .navbar-toggle {
        background-color: #545353;
        position: relative;
        top:8px;
    }

    .header .fa-angle-right {
        float: right;
    }

    .header .dropdown .dropdown-menu {
        background-color: #fff;
        padding: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        border: 0;
        -webkit-box-shadow: 0 0;
        -moz-box-shadow: 0 0;
        box-shadow: 0 0;
        left:auto;
        right:10px !important;
        margin-top:0 !important;
        border:1px solid #efefef !important;
        padding:10px 0px;
    }

    .header .dropdown .dropdown-menu a:hover {
        background:#EDEDED;
    }

    .header .dropdown .dropdown-menu a {
        background: #fff;
        padding: 12px 20px;
        margin-bottom: 1px;
        letter-spacing: 1px;
        font-size: 14px;
        text-transform:uppercase;
    }

    .header .dropdown .dropdown-menu a i.fa {
        color:#337ab7;
    }

    .social-icons-search {
        margin-top: 11px;
        margin-bottom: 11px;
        overflow: hidden;
    }

    .top-search {
        position: relative;
        width: 230px;
    }

    .top-search input[type="text"] {
        background-color: transparent;
        border: 1px solid #444444;
        border-radius: 1px;
        padding: 6px 14px;
        width: 100%;
        font-size: 10px;
        letter-spacing: 1px;
        color: #ffffff;
        padding-right: 30px;
    }

    .top-search input[type="text"]:focus {
        border: 1px solid #5f5f5f;
        outline: none;
    }

    .top-search input[type="submit"] {
        font-family: FontAwesome;
        background-color: transparent;
        border: 0;
        color: #aaaaaa;
        position: absolute;
        top: 1px;
        right: 0;
        font-size: 11px;
    }

    .top-social-icons {
        margin-top: 4px;
        margin-left: 20px;
    }

    .top-social-icons a {
        color: #ffffff;
        margin-left: 10px;
    }

    .main-content {
        margin-top: 60px;
    }

/*--------------------------------------------------------------
# Sticky
--------------------------------------------------------------*/
article.sticky {
    position: relative;
}

.sticky .featured-post {
    background-color: #22569E;
    color: #fff;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 26px;
    height: 26px;
    text-align: center;
    line-height: 26px;
    font-size: 14px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    z-index: 99;
}

/*--------------------------------------------------------------
# Default css
--------------------------------------------------------------*/
.entry-content address, .comment-content address {
    background: none repeat scroll 0 0 #f1f1f1;
    border-left: 5px solid #ddd;
    padding: 10px 10px 10px 20px;
}

.entry-content table, .comment-content table {
    border-right: 1px solid #ddd;
    border-top: 1px solid #ddd;
    margin-bottom: 20px;
}

.entry-content table tr, .comment-content table tr {
    border-bottom: 1px solid #ddd;
}

.entry-content table tr th, .comment-content table tr th {
    background: none repeat scroll 0 0 #eee;
}

.entry-content table tbody tr th {
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
}

.entry-content table tr th, table tr td, .comment-content table tr td {
    padding: 10px;
}

.entry-content p {
    margin: 0 0 20px;
}

.entry-content h2, .entry-content h3 {
    font-size: 22px;
    margin-top: 15px;
}

.entry-content p + h2, .entry-content p + h3 {
    margin-top: 40px;
}

.entry-content h1,
.entry-content h2,
.entry-content h3,
.entry-content h4,
.entry-content h5,
.entry-content h6 {
    margin-bottom: 15px;
    line-height: 30px;
    margin-top: 0;
}

.entry-content ol,
.entry-content ul {
    margin-bottom: 20px;
}

.entry-content li {
    margin-bottom: 10px;
}

/*--------------------------------------------------------------
# Accessibility
--------------------------------------------------------------*/
/* Text meant only for screen readers. */
.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
}

.screen-reader-text:focus {
    background-color: #f1f1f1;
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    clip: auto !important;
    color: #21759b;
    display: block;
    font-size: 14px;
    font-weight: bold;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000; /* Above WP toolbar. */
}

/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/
.alignleft {
    display: inline;
    float: left;
    margin-right: 1.5em;
}

.alignright {
    display: inline;
    float: right;
    margin-left: 1.5em;
}

.aligncenter {
    clear: both;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

/*--------------------------------------------------------------
# Captions
--------------------------------------------------------------*/

.wp-caption-text {
    background: none repeat scroll 0 0 #f5f5f5;
    font-size: 14px;
    margin-bottom: 15px;
    padding: 4px 10px;
}

.wp-caption-text {
    text-align: center;
}

/* Button */
.more-link {
    color: #22569E;
    font-size: 12px;
    border: 1px solid #e2e2e2;
    border-radius: 0;
    padding: 11px 20px;
    margin: 30px auto 0;
    width: 190px;
    display: block;
    text-align: center;
    letter-spacing: 1px;
}

.more-link:hover {
    color: #ffffff;
    background-color: #22569E;
    border-color: #22569E;
}

a.ss-read-more {
    line-height: 15px;
    border-width: 1px;
    padding: 13px 25px;
    letter-spacing: 1px;
    font-weight: 400;
    font-size: 14px;
    display: inline-block;
    color: #fff;
    width: auto;
    margin-top: 20px;
    background: #22569E;
    text-transform: uppercase;
}

/*----------------------------------------
    Preloader
    ------------------------------------------*/
    #st-preloader {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 999999;
        display: block;
        background: #fff;
    }

    .st-preloader-circle {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 36px;
        height: 36px;
        margin: -18px 0 0 -18px;
        border-radius: 50%;
        border: 2px solid;
        border-top-color: rgba(0, 0, 0, 0.65);
        border-bottom-color: rgba(0, 0, 0, 0.15);
        border-left-color: rgba(0, 0, 0, 0.65);
        border-right-color: rgba(0, 0, 0, 0.15);
        -webkit-animation: tb-preloader-circle 0.8s linear infinite;
        animation: tb-preloader-circle 0.8s linear infinite;
    }

    @keyframes tb-preloader-circle {
        from {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @-webkit-keyframes tb-preloader-circle {
        from {
            -webkit-transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
        }
    }

    /* slider */
    .slider-text {
        color: #fff;
    }

    .main-slide {
        -webkit-background-size: cover;
        background-size: cover;
        height: 100%;
        width: 100%;
        max-height:500px;
    }

    .carousel-control.right {
        right: 0;
        left: auto;
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 100%);
        background-repeat: repeat-x;
    }

    .carousel-control.left {
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 100%);
        background-repeat: repeat-x;
    }

    .carousel-control {
        transition: all .5s;
    }

    #myCarousel .carousel-control {
        display: block;
        position: absolute;
        top: 50%;
        margin-top: -21px;
        background-color: transparent;
        border: 2px solid #fff;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        line-height: 33px;
        -webkit-transition: 300ms;
        -moz-transition: 300ms;
        -o-transition: 300ms;
        transition: 300ms;
        font-size: 23px;
        visibility: hidden;
        opacity: 0;
    }

    #myCarousel:hover .carousel-control {
        visibility: visible;
        opacity: 1;
    }

    #myCarousel .carousel-control:hover {
        background-color: #22569E;
        border-color: #22569E;
    }

    .carousel-control.right {
        margin-right: 15px;

    }

    .carousel-control.left {
        margin-left: 15px;
    }

    .slider-text {
        background: rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: #fff;
        padding: 150px 20px 200px;
    }

    .slider-text h2 {
        line-height: 50px;
        border-width: 0;
        margin: 16px 20px 0 20px;
        padding: 0 0 20px;
        letter-spacing: 0;
        font-weight: 600;
        font-size: 40px;
        color: #fff;
        text-shadow: 1px 1px 1px rgba(150, 150, 150, 1);
        text-transform: none;
    }

    /* Post */
    .list-post .post-thumb {
        width: 270px;
        margin-bottom: 0;
    }

    article.post.list-post {
        margin-bottom: 0;
    }

    .post-in {
        margin-top: -5px;
    }

    .post .post-in a {
        color: #22569E;
        font-size: 10px;
        font-weight: 700;
    }

    h2.entry-title {
        font-size: 18px;
        color: #222222;
        margin-top: 10px;
        margin-bottom: 18px;
    }

    h2.entry-title a {
        color: #222222;
    }

    article.post {
        margin-bottom: 50px;
    }

    .post-thumb {
        margin-bottom: 18px;
        position: relative;
        overflow: hidden;
    }

    .post-thumb img {
        width: 100%;
        object-fit:cover;
    }

    .by-on {
        font-size: 12px;
        color: #aaaaaa;
        margin-top: -16px;
        margin-bottom: 15px;
    }

    .by-on a {
        color: #aaaaaa;
    }

    article.large-post {
        margin-bottom: 75px;
    }

    .large-post .entry-title {
        font-size: 30px;
        margin-bottom: 17px;
        line-height: 40px;
    }

    .large-post .entry-header {
        margin-bottom: 28px;
    }

    .large-post .post-thumb {
        margin-bottom: 28px;
    }

    .large-post .social-share {
        margin-top: 30px;
        text-align: center;
    }

    .large-post .social-border {
        width: 100%;
        height: 1px;
        background-color: #dddddd;
        margin-top: -19px;
    }

    .large-post .social-share-icons {
        background-color: #fff;
        display: inline-block;
        margin: 0 auto;
        position: relative;
        padding-left: 20px;
        padding-right: 20px;
    }

    .large-post .social-share-icons a {
        color: #dddddd;
        border: 1px solid #dddddd;
        width: 32px;
        height: 32px;
        display: inline-block;
        line-height: 31px;
        border-radius: 50%;
        margin: 3px;
    }

    .large-post .social-share-icons a:hover {
        background-color: #22569E;
        color: #FFFFFF;
        border-color: #22569E;
    }

    .share-this {
        color: #aaaaaa;
        font-size: 12px;
        margin-bottom: 20px;
    }

    .navigation {
        padding-top: 30px;
        padding-bottom: 30px;
        overflow: hidden;
        font-size: 13px;
        font-family: 'Montserrat', sans-serif;
    }

    .navigation a {
        color: #AAAAAA;
    }

    .single-post-meta {
        overflow: hidden;
        margin-top: 30px;
    }

    .single-post-meta i {
        color: #888888;
        margin-right: 3px;
    }

    .single-post-meta .tags {
        width: 80%;
    }

    .single-post-meta .tags a {
        color: #aaaaaa;
        background-color: #f5f5f5;
        font-size: 10px;
        font-family: 'Montserrat', sans-serif;
        padding: 6px 10px;
        margin-bottom: 4px;
        display: inline-block;
    }

    .single-post-meta .comment-canter a {
        color: #aaaaaa;
        background-color: #f5f5f5;
        font-size: 10px;
        font-family: 'Montserrat', sans-serif;
        padding: 6px 10px;
    }

    /* Pagination */
    .pagination {
        padding-top: 30px;
        padding-bottom: 30px;
        margin: 0;
        overflow: hidden;
        font-size: 13px;
        font-family: 'Montserrat', sans-serif;
    }

    .pagination li a,
    .pagination li span {
        color: #AAAAAA;
        border: 1px solid #e5e5e5;
        padding: 8px 15px;
        display: inline-block;
    }

    .pagination li:last-child a,
    .pagination li:last-child span,
    .pagination li:first-child a,
    .pagination li:first-child span {
        border-radius: 0;
    }

    .pagination li a:focus,
    .pagination li a:hover,
    .pagination li span:focus,
    .pagination li span.current,
    .pagination li span:hover {
        color: #fff;
        background-color: #22569E;
        border-color: #22569E;
    }

    /* Widget */
    .widget .instagram-pics li {
        border: 0;
        padding: 0;
        width: 31%;
        float: left;
        margin: 4px;
    }

    .clear {
        clear: both;
    }

    aside.widget {
        margin-bottom: 35px;
        padding: 25px;
        background: #FFF;
    }

    .widget .widget-title {
        position: relative;
        font-size: 14px;
        margin-top: 0;
        margin-bottom: 44px;
    }

    .widget .widget-title:after {
        content: '';
        position: absolute;
        bottom: -14px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 30px;
        height: 2px;
        background-color: #222222;
    }

    .about-me-widget img {
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        margin: 0 auto 20px;
    }

    .my-social {
        margin-top: 20px;
    }

    .about-me-widget .my-social a {
        color: #222;
        margin: 5px;
        font-size: 16px;
    }

    .about-me-widget .my-social a:hover {
        color: #777777;
    }

    .widget ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .widget ul li {
        border-bottom: 1px solid #e2e2e2;
        padding: 6px 0;
    }

    .widget ul li ul {
        padding-left: 15px;
        border-top: 1px solid #e2e2e2;
        margin-top: 6px;
    }

    .widget ul li:last-child {
        border-bottom: 0;
        padding-bottom: 0;
    }

    .widget ul li:first-child {
        padding-top: 0;
    }

    .widget ul li a {
        color: #222222;
        line-height: 26px;
        font-size: 16px;
    }

    .widget_mc4wp_widget {
        border: 1px solid #e2e2e2;
        padding: 26px;
    }

    .widget_mc4wp_widget input[type="email"] {
        border: 1px solid #e2e2e2;
        width: 100%;
        padding: 10px 14px;
        font-size: 12px;
        color: #aaaaaa;
        letter-spacing: 1px;
        margin-bottom: 20px;
    }

    .widget_mc4wp_widget input[type="submit"] {
        background-color: #22569E;
        color: #ffffff;
        border: 0;
        text-transform: uppercase;
        letter-spacing: 1px;
        width: 100%;
        padding: 10px;
    }

    .widget_mc4wp_widget input[type="submit"]:hover {
        background-color: #222222;
    }

    .textwidget {
        line-height: 24px;
        color: #222222;
    }

    /* Instafeed */

    .instagram-pinterest {
        margin-bottom: 70px;
    }

    .instagram-pinterest .nav-tabs {
        border-bottom: 0;
        width: 302px;
        display: block;
        margin: 70px auto 35px;
    }

    .instagram-pinterest .nav-tabs li {
        margin-bottom: 0;
    }

    .instagram-pinterest .nav-tabs li a {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        background-color: #fbfbfb;
        text-transform: uppercase;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        color: #333333;
        margin: 0;
        padding: 11px 31px;
        border: 2px solid #22569E;
    }

    .instagram-pinterest .nav-tabs li.active a,
    .instagram-pinterest .nav-tabs li.active a:focus,
    .instagram-pinterest .nav-tabs li.active a:hover {
        color: #FFFFFF;
        background-color: #22569E;
        border: 2px solid #22569E;
    }

    #pinterestfeed .item img {
        width: 100%;
    }

    .null-instagram-feed {
        overflow: hidden;
    }

    #instafeed .overlay,
    #pinterestfeed .overlay {
        position: absolute;
        background-color: #22569E;
        width: 100%;
        height: 100%;
        opacity: 0;
        -webkit-transition: all 0.3s ease-out 0s;
        -moz-transition: all 0.3s ease-out 0s;
        -ms-transition: all 0.3s ease-out 0s;
        -o-transition: all 0.3s ease-out 0s;
        transition: all 0.3s ease-out 0s;
    }

    #instafeed .overlay i,
    #pinterestfeed .overlay i {
        position: absolute;
        font-size: 28px;
        top: 50%;
        left: 50%;
        margin-top: -14px;
        margin-left: -12px;
        color: #ffffff;
    }

    #instafeed .item:hover .overlay,
    #pinterestfeed .item:hover .overlay {
        opacity: 0.6;
    }

    #instafeed .owl-controls .owl-buttons div,
    #pinterestfeed .owl-controls .owl-buttons div {
        color: #EEEEEE;
        display: inline-block;
        zoom: 1;
        margin: 0;
        font-size: 14px;
        background-color: transparent;
        opacity: 1;
        border: 2px solid #EEEEEE;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        width: 30px;
        height: 30px;
    }

    #instafeed .owl-controls .owl-buttons div:hover,
    #pinterestfeed .owl-controls .owl-buttons div:hover {
        opacity: 1;
        background-color: #22569E;
        color: #FFFFFF;
        border-color: #22569E;
    }

    #instafeed,
    #pinterestfeed {
        position: relative;
        overflow: hidden;
    }

    #instafeed .owl-controls,
    #pinterestfeed .owl-controls {
        margin-top: 0;
    }

    #instafeed .owl-controls .owl-buttons .owl-next,
    #pinterestfeed .owl-controls .owl-buttons .owl-next {
        position: absolute;
        right: -38px;
        top: 50%;
        margin-top: -21px;
        -webkit-transition: 300ms;
        -moz-transition: 300ms;
        -o-transition: 300ms;
        transition: 300ms;
    }

    #instafeed .owl-controls .owl-buttons .owl-prev,
    #pinterestfeed .owl-controls .owl-buttons .owl-prev {
        position: absolute;
        left: -38px;
        top: 50%;
        margin-top: -21px;
        -webkit-transition: 300ms;
        -moz-transition: 300ms;
        -o-transition: 300ms;
        transition: 300ms;
    }

    #instafeed:hover .owl-controls .owl-buttons .owl-prev,
    #pinterestfeed:hover .owl-controls .owl-buttons .owl-prev {
        left: 10px;
    }

    #instafeed:hover .owl-controls .owl-buttons .owl-next,
    #pinterestfeed:hover .owl-controls .owl-buttons .owl-next {
        right: 10px;
    }

    /* Footer */
    .footer-logo {
        margin-bottom: 80px;
        display: inline-block;
    }

    h3.footer-dec {
        color: #aaaaaa;
        font-size: 24px;
        font-style: italic;
        line-height: 34px;
        margin-bottom: 65px;
        margin-top: 0;
    }

    footer.footer {
        background-color: #f5f5f5;
        color: #888888;
        font-size: 13px;
        padding-top: 22px;
        padding-bottom: 22px;
    }

    footer.footer a {
        color: #888888;
    }

    footer.footer a:hover {
        color: #22569E;
    }

    /* User Profile */
    .user-profile {
        margin-top: 0;
        margin-bottom: 60px;
    }

    .user-profile .author-avatar img {
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
    }

    .profile-heading {
        margin-bottom: 6px;
    }

    .profile-heading a {
        color: #222222;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        letter-spacing: .5px;
    }

    .author-social-profile {
        margin-top: 8px;
    }

    .author-social-profile a {
        color: #777777;
        margin-right: 5px;
    }

    .author-social-profile a:hover {
        color: #222222;
    }

    /* Related Posts */
    .related-posts {
        margin-bottom: 60px;
    }

    .common-title {
        font-size: 14px;
        color: #222222;
        letter-spacing: .5px;
        margin-bottom: 28px;
        margin-top: 0;
    }

    .related-posts header h3 {
        font-size: 14px;
        margin-top: 13px;
        margin-bottom: 8px;
    }

    .related-posts header h3 a {
        color: #222222;
    }

    .related-posts .by-on {
        margin-top: 0;
    }

    /* === Comments === */
    #respond input[type="text"],
    #respond input[type="email"],
    #respond input[type="url"] {
        display: block;
        width: 100%;
        height: 38px;
        font-size: 12px;
        border: 1px solid #e2e2e2;
        background: #fff;
        margin-bottom: 24px;
        padding: 0 15px;
        color: #aaaaaa;
        border-radius: 0;
    }

    #respond input:focus[type="text"],
    #respond input:focus[type="email"],
    #respond input:focus[type="url"],
    #respond textarea:focus {
        outline: none;
        border-color: #22569E;
    }

    #respond textarea {
        display: block;
        width: 100%;
        height: 140px;
        font-size: 12px;
        border: 1px solid #e2e2e2;
        margin-bottom: 24px;
        color: #aaaaaa;
        resize: none;
        padding: 10px 15px;
        border-radius: 0;
    }

    #respond .form-submit {
        font-size: 12px;
    }

    #respond .logged-in-as {
        margin-left: 15px;
    }

    #respond #reply-title {
        color: #222222;
        font-size: 14px;
        text-transform: uppercase;
        margin-top: 50px;
        margin-bottom: 26px;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
    }

    #respond #reply-title small a {
        color: #FD3535;
        margin-left: 10px;
    }

    #comments ul.comment-list {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    #comments ul.comment-list ul {
        list-style: none;
        padding-left: 30px;
    }

    #comments .comment-reply {
        float: right;
    }

    #comments .comment-reply a {
        color: #aaaaaa;
        padding: 3px 12px;
        display: inline-block;
        font-size: 12px;
        background-color: #f5f5f5;
    }

    #comments .comment-reply a:hover {
        color: #fff;
        background-color: #22569E;
    }

    #comments .comment-author {
        margin-top: 0;
        margin-bottom: 5px;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        letter-spacing: .30px;
    }

    #comments .comment-author a,
    #respond .logged-in-as a {
        color: #222222;
    }

    #comments .comment-author a:hover,
    #respond .logged-in-as a:hover {
        color: #22569E;
    }

    #comments .comment-date {
        color: #AAAAAA;
        font-size: 11px;
    }

    #comments .comment-content {
        margin-top: 5px;
    }

    #comments .comment-canter li {
        list-style: initial;
    }

    #comments .comment-content p {
        margin-bottom: 10px;
    }

    #comments .comment-body {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #EEEEEE;
    }

    #comments .comment-avartar img {
        border-radius: 50%;
    }

    #comments .edit-link {
        font-size: 12px;
    }

    .bypostauthor {
        display: block;
    }

    .form-submit input[type="submit"] {
        background-color: #eeeeee;
        border: 0;
        color: #aaaaaa;
        text-transform: uppercase;
        font-size: 13px;
        padding: 7px 22px;
    }

    .form-submit input[type="submit"]:hover {
        background-color: #22569E;
        color: #FFFFFF;
    }

    .widget select {
        width: 100%;
        border: 1px solid #ddd;
        padding: 6px 8px;
    }

    /* --- Gallery Post Slider --- */
    #blog-gallery-slider.carousel {
        overflow: hidden;
    }

    #blog-gallery-slider .carousel-control.left,
    #blog-gallery-slider .carousel-control.right {
        top: 50%;
        left: -50px;
        margin-top: -17px;
        text-shadow: none;
        transition: all .3s linear;
        background-image: none;
        display: block;
        width: 30px;
        height: 30px;
        text-align: center;
        color: #eeeeee;
        font-size: 20px;
        line-height: 26px;
        opacity: 1;
        border-radius: 50%;
        border: 2px solid #eeeeee;
    }

    #blog-gallery-slider .carousel-control.left:hover,
    #blog-gallery-slider .carousel-control.right:hover {
        opacity: 1;
        background-color: #22569E;
        border-color: #22569E;
        color: #fff;
    }

    #blog-gallery-slider .carousel-control.right {
        left: auto;
        right: -50px;
    }

    #blog-gallery-slider.carousel:hover .carousel-control.left {
        left: 10px;
    }

    #blog-gallery-slider.carousel:hover .carousel-control.right {
        right: 10px;
    }

    #blog-gallery-slider .carousel-control .glyphicon-chevron-left,
    #blog-gallery-slider .carousel-control .glyphicon-chevron-right {
        line-height: 80px;
        top: 0;
        left: 10px;
    }

    .post-thumb .img-caption {
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        font-size: 12px;
        padding: 8px 15px;
        position: absolute;
        left: 0;
        bottom: -20px;
        width: 100%;
        letter-spacing: .5px;
        transition: all .3s linear;
        opacity: 0;
    }

    .post-thumb:hover .img-caption {
        bottom: 0;
        opacity: 1;
    }

    /* video post */
    .fluid-width-video-wrapper {
        width: 100%;
        padding-top: 56.25%;
        position: relative;
    }

    .entry-video iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    /* Contact Page */
    .sapphire-contact-form input[type="text"],
    .sapphire-contact-form input[type="email"],
    .sapphire-contact-form select {
        border: 1px solid #e2e2e2;
        padding: 7px;
        margin-bottom: 16px;
        width: 100%;
        border-radius: 0;
    }

    .sapphire-contact-form textarea {
        border: 1px solid #e2e2e2;
        padding: 7px;
        margin-bottom: 16px;
        width: 100%;
        height: 150px;
    }

    .btn-submit {
        border-radius: 0;
        padding: 6px 8px;
        text-transform: uppercase;
    }

    .btn-submit:hover {
        background-color: #222222;
        color: #fff;
        border-color: #222222;
    }

    /* masanory post */
    .masonery_area {
    }


    /*CUSTOM STYLES*/

    .header .navbar-nav {
        margin-top: 10px;
    }

    .logo-header {
        display: inline-block;
        float: left;
        margin-top: -10px;
    }

    .main-row {
        margin-left: 0;
    }

    .left-main-col {
        margin-bottom: 40px;
    }

    .left-main-col .row {
        background: #fff;
        padding: 25px;
        box-sizing:border-box;
        margin-right: 0px;
    }

    .carousel {
        margin-top: 55px;
        margin-bottom: -25px;
    }

    .main-wrap {
        padding-top: 35px;
    }

    .category-lead h1 {
       text-transform:uppercase;
       color:#22569E;
       font-family: 'Lora', serif;
       text-align:center;
       position:relative;
       margin-bottom: 50px;
   }

   .category-lead h1:after {
    content: '';
    position: absolute;
    bottom: -14px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 60px;
    height: 2px;
    background-color: #222222;
}

.lead {
   border-bottom:1px solid #eee;
   padding-bottom: 15px;
   margin-bottom: 30px;
   font-size:14px;
}

/*-----TABLES---*/

.step {
    background:#22569E;
    color:#fff;
    width:25px;
    height:25px;
    display:inline-block;
    text-align:center;
    line-height:25px;
    font-size:15px;
    position:relative;
    top:-3px;
    margin-right:10px;
    border-radius:25px;
}

.ovalBox {
    border: 1px solid #efefef;
    position: relative;
    padding: 20px;
    margin: 20px 0;
    font-size: 16px;
    background: #f5f5f5;
}

.ovalBox .xpart {
    margin-bottom: 10px;
    clear:both;
}

.ovalBox input[type="text"], .ovalBox input[type="date"],
.ovalBox select
{
    font-size: 26px;
    line-height:26px;
    padding:5px;
}

.ovalBox select {
    font-size:18px;
    line-height:26px;
    height:40px;
    border-radius:0;
    background: #FFF;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: right 50%;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
}

.ovalBox label {
    width:100px;
    float:left;
    display:block;
    font-weight: normal;
    text-align: right;
    margin-right: 20px;
    line-height:38px;
}

p.poz img {
    margin-right: 8px;
}

.choose_sex {
    line-height:38px;
    font-size: 24px;
}

.choose_sex input[type="radio"] {
    position: relative;
    top:-5px;
}

.boxKalkulatorPorodu label {
    width:300px;
}

.boxKalkulatorBMI input[type="text"], .boxKalkulatorPorodu input[type="text"], .boxKalkulatorPorodu input[type="date"] {
    text-align:right;
}

.ovalBoxProfilaktyka {
    border: none;
    margin-top: 30px;
}

table.standard th,
table.standard td {
    padding: 5px 10px;
    border-bottom: 1px solid #efefef;
    vertical-align: top;
}

table.standard th {
    background-color: #efefef;
    border-color: #dedede;
    vertical-align: middle;
}

table.profilaktyka th.czestosc {
    width: 150px;
}

table.clear td {
    padding: 2px;
}

table.pakiety {
    width:100%;
}

table.pakiety th {
    text-align:center;
    font-size:12px;
    background:#fff;
    padding:5px 10px;
    color:#3165AC;
    border-bottom:2px solid #efefef;
}

table.pakiety tr.zebra td {
    background:#F7F5E4;
}

table.pakiety tr.activeTr td {
    background:#EAE7C4;
}

table.pakiety td {
    padding:8px 10px;
    border-bottom:1px solid #efefef;
    font-size:12px;
    text-align:center;
}

table.pakiety td p {
    font-size:10px;
    margin:0;
}


table.pakiety td.firstCol {
    text-align:left;
    padding-left:5px;
    width:250px;
}

table.pakiety tr.subheader td {
    padding:20px 5px;
}

table.pakiety tr.prices td {
    font-size:11px;
}

table.pakiety tr.prices td span {
    font-weight:bold;
    color:#3F3A2F;
    font-size:24px;
}

table.pakiety tr.oblicz td img {
    width:90px;
}


/*-----PROFILAKTYKA---*/

td.labelColor9 {background:url(/dist/images/dens1.gif) no-repeat 10px 50%;padding-left:25px !important;vertical-align:middle;}
td.labelColor8 {background:url(/dist/images/dens2.gif) no-repeat 10px 50%;padding-left:25px !important;vertical-align:middle;}
td.labelColor7 {background:url(/dist/images/dens3.gif) no-repeat 10px 50%;padding-left:25px !important;vertical-align:middle;}
td.labelColor6 {background:url(/dist/images/dens4.gif) no-repeat 10px 50%;padding-left:25px !important;vertical-align:middle;}
td.labelColor5 {background:url(/dist/images/dens5.gif) no-repeat 10px 50%;padding-left:25px !important;vertical-align:middle;}
td.labelColor4 {background:url(/dist/images/dens6.gif) no-repeat 10px 50%;padding-left:25px !important;vertical-align:middle;}
td.labelColor3 {background:url(/dist/images/dens7.gif) no-repeat 10px 50%;padding-left:25px !important;vertical-align:middle;}
td.labelColor2 {background:url(/dist/images/dens8.gif) no-repeat 10px 50%;padding-left:25px !important;vertical-align:middle;}
td.labelColor1 {background:url(/dist/images/dens9.gif) no-repeat 10px 50%;padding-left:25px !important;vertical-align:middle;}



.ovalBoxProfilaktyka {
    padding:0px !important;
}

h2.profilaktykaTitle {
    margin-bottom:20px;
    margin-top: 50px;
    font-size: 23px;
}


table.profilaktyka td {
    vertical-align:middle;
    padding-top:15px;
    padding-bottom:15px;
}

table.profilaktyka th {
    background: #efefef;
    border-bottom:1px solid #dedede;
    padding-top:7px;
    padding-bottom:7px;
    font-size:13px;
}

.profilaktyka fieldset p.wiek {
    border-left:none;
    margin-left:0 !important;
    padding-left:0;
    width:110px;
}

.profilaktyka fieldset p.plec {
    width:180px;
}

.profilaktyka fieldset p.submit {
    border-left:none;
    position:relative;
    top:10px;
}

.widget_calculator  .cat-item {
    padding:12px 0;
}

.widget_calculator .cat-item i {
    font-size: 26px;
    margin-right: 13px;
    color:#22569E;
    position: relative;
    top: 3px;
}

aside.widget-banners {
    padding:0;
    border:5px solid #fff;
}

.widget-banners .media {
    border-bottom: 1px solid #e2e2e2;
    padding:15px 25px;
    margin:0;
}

.widget-banners .media:last-child {
    border-bottom: 0;
}

.widget-banners h2.entry-title {
    font-size: 13px;
    margin-top: 0;
    margin-bottom: 8px;
    line-height: 18px;
}

.widget-banners .entry-meta {
    color: #aaaaaa;
    font-size: 12px;
}

.widget-banners .media:hover {
    background: #ededed;
}

.widget-banners .pull-left {
    /* padding:0 !important; */
}

.widget-banners .pull-left img {
    max-width:60px;
    position:relative;
    left:-5px;
    top:5px;
}


.widget-banners .media-body h2 {
    font-size:17px !important;
    line-height:25px !important;
    padding-top:15px !important;
}


.banner-article {
    border:1px solid #e2e2e2;
    padding:5px;
    text-align:center;
}

span.r1 {
    color:#22569E;
    font-weight:400;
}

span.r2 {
}

footer .footer-links {
    display: inline-block;
}

footer .footer-links li {
    list-style:none;
    display:inline-block;
    margin-right: 12px;
}

footer .footer-links li[not:last]:after {
    height:8px;
    width:1px;
}

footer .footer-links li:after {
  content: ' |';
  margin-left: 10px;
}

footer .footer-links li:last-child:after {
  content: '';
}

.messageError {
    border: 2px solid red;
    padding: 15px 15px 5px 15px;
}

button.btn-orange-rounded {
    background:rgba(244, 128, 36, 1);
    color:#fff !important;
    padding: 7px 16px;
    font-size: 16px;
    border-radius: 6px;
    text-transform: uppercase;
}

button.btn-orange-rounded:hover {
    background:rgba(244, 128, 36, 0.8);
}

button.btn-orange-rounded i.fa {
    margin-left: 5px;
}

#banner-sklep:hover {
    cursor:pointer;
}

.sidebar-banner .textwidget * {
    position: relative;
}

.sidebar-banner .textwidget h2 {
    margin-top: 0;
    color: #FFF;
    text-shadow: 1px 1px 1px rgba(150, 150, 150, 1);
}

.sidebar-banner .textwidget {
    text-align:center;
    padding:45px 15px;
    position: relative;
}

.sidebar-banner .textwidget:before {
    content:"";
    width:100%;
    height:100%;
    background:#9e6c2c;
    display:block;
    position: absolute;
    top:0;
    left:0;
    opacity:0.25
}

.layer_cookie                       {width: 100%; display: none; background: #FFF; text-align: center; padding: 4px 20px 20px; z-index: 100000; position: fixed; left: 0; margin-left: 0px; bottom: 0; height: auto;border: 1px solid #cdcdcd; -webkit-box-shadow:  0px 0px 5px 1px #cdcdcd; box-shadow:  0px 0px 5px 1px #cdcdcd;}
.close_cookie_info                  {position: absolute; top: 12px; right: 60px; width: 12px; height: 15px; color: #000; font-weight: bold; font-size: 15px; text-decoration: none;}
.layer_cookie p.mptitle              {font-size: 14px; margin: 10px 0 15px; font-weight: bold;}
.layer_cookie p                     {font-size: 11px; margin: 0px;}
.layer_cookie a                     {color: #FFF; background: #22569E; display: inline-block;  margin-top: 15px;  font-weight: bold; font-size: 14px; border: 1px solid #002b41; padding: 5px 10px; clear: both;}
